<template>
    <div class="Home">
      <!-- HERO -->

    <page-header/>

      <section>
        <div class="uk-container ">
          <div class="uk-text-center uk-margin">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li>
                <a href>Home</a>
              </li>
              <li>
                <span>Examination
                </span>
              </li>
            </ul>
            <!--<h1 class="uk-margin-small-top uk-margin-remove-bottom">Student register</h1>-->
          </div>

          <div class="uk-width-1-1 uk-width-expand@m uk-margin-medium-top">

            <div class="uk-margin">
              <div>
                <ul class="uk-subnav uk-subnav-pill myswitcher uk-float-left"
                    uk-switcher="connect: .myswitchcontent; animation: uk-animation-fade">
                  <li><a @click="tab1" href="#">Examination during reception</a></li>
                  <li><a @click="tab2" href="#">Exam I received</a></li>
                </ul>
                <div class="uk-float-right">
                  <div uk-form-custom="target: true">

                    <input style="height:48px; min-width: 400px" v-model="name"
                           class="uk-input uk-form-width-medium uk-text-center"
                           type="text" placeholder="Exam name">
                  </div>
                  <button type="button" style="height:48px" @click="search" class="uk-button uk-button-primary">Search</button>
                </div>


              </div>

              <ul class="uk-switcher uk-flex uk-flex-wrap uk-flex-wrap-around myswitchcontent uk-margin uk-width-1-1">

                <li class="uk-width-1-1" v-if="exams!=null">

                  <!--organization 1-->
                  <div  v-for="item in exams"
                       :item="item" :key="item" class="uk-margin">
                    <p class="uk-flex uk-flex-middle uk-text-center uk-margin uk-text-uppercase"><span
                      class="uk-display-block uk-float-left uk-margin-small-right"
                      style="height: 30px; width: 3px; background: rgb(1, 176, 255);"></span>{{item.organizName}}
                    </p>
                    <div
                      class="uk-grid uk-grid-small uk-child-width-1-4@m uk-margin-small-top uk-grid-match"
                      data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div > .uk-card; delay: 200"
                      data-uk-grid
                    >

                      <div v-for="exam in item.exams"
                           :item="exam" :key="exam">
                        <div
                          class="examitem uk-flex uk-flex-column uk-padding-small uk-card uk-card-default uk-card-body"

                          data-uk-scrollspy-class="uk-animation-slide-left-small"
                        >
                          <div class="uk-flex-1">
                            <div class="uk-flex uk-flex-middle uk-flex-center">
                            <span style="font-size: 4rem; font-weight: 200; line-height: 1em">
                              <img width="90" src="../../assets/septentrions.png" alt="Logo">
                            </span>
                            </div>
                            <div
                              class="uk-text-small uk-text-center  uk-margin"
                            ><h5>{{exam.examName}}</h5>

                            </div>
                          </div>
                          <div class="uk-flex-2">
                            <div class="uk-clearfix">
                              <span style="margin-top: 7px" class="uk-flex-first uk-float-left uk-text-muted">D-3</span>
                              <a href="#" class="uk-button uk-button-default uk-float-right">Receive</a>


                            </div>
                          </div>

                        </div>

                      </div>


                    </div>
                  </div>
                  <div class="uk-flex uk-flex-center uk-margin-large">
                    <v-pagination v-model="currentPage"
                                  :page-count="pages"
                                  :classes="uikitClasses"
                                  :labels="Labels"
                                  @change="onChange"></v-pagination>

                  </div>
                </li>

                <li class="uk-width-1-1" v-if="myexams!=null">

                  <!--organization 1-->
                  <div  v-for="(item,indess) in myexams" :key="indess" class="uk-margin">
                    <p class="uk-flex uk-flex-middle uk-text-center uk-margin uk-text-uppercase"><span
                      class="uk-display-block uk-float-left uk-margin-small-right"
                      style="height: 30px; width: 3px; background: rgb(1, 176, 255);"></span>{{item.organizName}}
                    </p>
                    <div
                      class="uk-grid uk-grid-small uk-child-width-1-4@m uk-margin-small-top uk-grid-match"
                      data-uk-scrollspy="cls: uk-animation-slide-bottom-small; target: > div > .uk-card; delay: 200"
                      data-uk-grid
                    >

                      <div v-for="exam in item.exams"
                           :item="exam" :key="exam">
                        <div
                          class=" uk-flex uk-flex-column uk-padding-small examitem uk-card uk-card-default uk-card-body"
                          data-uk-scrollspy-class="uk-animation-slide-left-small"
                        >
                          <div class="uk-flex-1">
                            <div class="uk-flex uk-flex-middle uk-flex-center">
                            <span style="font-size: 4rem; font-weight: 200; line-height: 1em">
                              <img width="90" src="../../assets/septentrions.png" alt="Logo">
                            </span>
                            </div>
                            <div
                              class="uk-text-small uk-text-center  uk-margin"
                            ><h5>{{exam.examName}}</h5>

                            </div>
                          </div>
                          <div class="uk-flex-2">
                            <div class="uk-clearfix">
                              <span style="margin-top: 7px" class="uk-flex-first uk-float-left uk-text-muted">D-3</span>
                              <a href="#" class="uk-button uk-button-default uk-float-right">접수하기</a>


                            </div>
                          </div>

                        </div>

                      </div>


                    </div>
                  </div>
                  <div class="uk-flex uk-flex-center uk-margin-large">
                    <v-pagination v-model="currentPage2"
                                  :page-count="pages2"
                                  :classes="uikitClasses"
                                  :labels="Labels"
                                  @change="onChange2"></v-pagination>

                  </div>
                </li>

              </ul>

            </div>

          </div>


        </div>


      </section>

    <page-footer/>

    </div>

</template>


<script scoped>
  import StudentService from '@/services/StudentService'
  import moment from 'moment'
  import vPagination from '@/components/globals/vue-plain-pagination'
  const PageHeader = () => import("./StudentHeaderNew.vue");
	const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  export default {
    name: 'Home',
    components: {
      vPagination,
      PageHeader,
      PageFooter
    },
    data() {
      return {
        name: null,
        error: null,
        exams: null,
        myexams: null,
        currentPage: 1,
        currentPage2: 1,
        pages: 0,
        pages2: 0,
        curtab: 1,
        uikitClasses: {
          ul: 'uk-pagination',
          li: '',
          liActive: 'uk-active',
          liDisable: 'uk-disabled',
          button: 'page-link'
        },
        Labels: {
          first: 'First',
          prev: '<span uk-pagination-previous></span>',
          next: '<span uk-pagination-next></span>',
          last: "<span uk-icon='chevron-double-right'; ratio = '1.2'></span>"
        }


      }
    },

    filters: {
      moment: function (date) {
        return moment(date).format('YYYY.MM.DD')
        // return moment(date).format('YYYY-MM-DD, h:mm:ss a')
      }
    },
    created() {
    },

    async mounted() {
      this.loaddata(this.currentPage)

    },
    methods: {

      tab1() {
        this.curtab = 1
        this.name = null
        this.loaddata(this.currentPage)
      },
      tab2() {
        this.curtab = 2
        this.name = null
        this.loaddata2(this.currentPage2)
      },
      search() {
        if (this.curtab === 1)
          this.loaddata(this.currentPage)
        else
          this.loaddata2(this.currentPage2)
      },

      async loaddata(page) {
        try {

          if (page > 0) {
            page = page - 1
          }

          const response = await StudentService.getOrganizationsExamList({
            page: page,
            name: this.name

          });

          console.log(response)

          if (response.status == 200) {
            this.exams = response.data.content;
            // this.currentPage = response.data.page;
            this.pages = response.data.totalPages;


            if (!this.exams.length) {
              this.isLoading = false
              return
            }
          }
        } catch (error) {
          console.log(error.response)
          this.error = error.response.data.message;
          this.scrollToTop()
        }

      },


      async loaddata2(page) {
        try {

          if (page > 0) {
            page = page - 1
          }

          const response = await StudentService.getUserOrganizationsExamList({
            page: page,
            name: this.name

          });

          console.log(response)

          if (response.status == 200) {
            this.myexams = response.data.content;
            // this.currentPage = response.data.page;
            this.pages2 = response.data.totalPages;


            if (!this.myexams.length) {
              this.isLoading = false
              return
            }
          }
        } catch (error) {
          console.log(error.response)
          this.error = error.response.data.message;
          this.scrollToTop()
        }

      },
      scrollToTop() {
        window.scrollTo(0, 0)
      }
      ,
      onChange: function () {
        // eslint-disable-next-line no-console
        console.log(`"currentPage" has been changed`)
        this.loaddata(this.currentPage)
      },
      onChange2: function () {
        // eslint-disable-next-line no-console
        console.log(`"currentPage" has been changed`)
        this.loaddata2(this.currentPage2)
      }

    }
    ,
    computed: {}

  }
</script>

<style scoped>
  .uk-pagination > li > a {
    font-size: 18px !important;
  }

  table tr td {
    border: 1px solid #ccc;
  }

  .examitem {
    border: 1px solid #ccc;
    background: #fff;
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
  }

  .examitem:hover {
    border: 1px solid rgb(1, 176, 255);
    transition: 0.2s;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
  }

  .myswitcher li a {
    padding: 15px;
    min-width: 147px;
    text-align: center;
    background: #e6e6e6;
  }

  .myswitcher li.uk-active a {

    background: rgb(1, 176, 255);
  }

  .myswitcher li:last-child {
    padding-left: 5px;
  }
</style>
